<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <!-- <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">공지사항 {{ viewModel.isModify ? '수정' : '등록' }}</strong>
        </div>
      </template> -->
      <template v-slot:tbl_body>
        <tr>
          <th>상태</th>
          <td>
            <SwitchCheck
              :value.sync="statusSync"
              :isSwitchLeft="true"
              text="활성화"
              offText="비활성화" />
          </td>
          <th>타겟구분<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Selectbox
              maxWidth="300px"
              :dataList="'user_target' | getSelectDataList"
              :value.sync="viewModel.model.target"/>
          </td>
        </tr>
        <tr>
          <th>제목<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <Input
              placeholder="공지사항 제목을 입력해주세요"
              :value.sync="viewModel.model.title"/>
          </td>
        </tr>
        <tr>
          <th>내용<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <EditorTiptap
              :menuDataList="['bold','italic','strike','underline','horizontalRule','highlight','link','image','youtube','title','bulletList','orderedList','blockquote','clear_format','undo','redo']"
              :maxLength="1000000"
              :value.sync="viewModel.model.contents"
              :imageFileSize="[1680,1680]"
              imageFileSizeType="MIN"/>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          :routeName="viewModel.isModify ? 'LANDING_BLOG_NEWS_DETAIL' : 'LANDING_BLOG_NEWS_LIST'"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
        <Button
          btnSize="medium"
          btnStyle="secondary"
          text="미리보기"
          @onClickBtn="viewModel.onClickNewsPreviewPopup()"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          :disabled="isVaild"
          @onClickBtn="viewModel.onComplete()"/>
      </div>
    </Sticky>
    <template v-slot:popup>
      <NewsPreviewPopup
        v-if="viewModel.isNewsPreviewPopup"
        :detailData="viewModel.model"
        @onClickClose="viewModel.onClickCloseNewsPreviewPopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import EditorTiptap from '@lemontree-ai/lemontree-admin-common-front/components/common/editor/EditorTiptap';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// popup
import NewsPreviewPopup from '@/views/landing/blog/news/view/popup/NewsPreviewPopup'
// viewModel
import NewsDetailViewModel from '@/views/landing/blog/news/viewModel/NewsDetailViewModel'

export default {
  name:'NewsRegister',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    SwitchCheck,
    Selectbox,
    Input,
    EditorTiptap,
    Sticky,
    Button,

    NewsPreviewPopup,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);


    // 복제하여 등록
    if (
      this.$route.name === 'LANDING_BLOG_NEWS_REGISTER' &&
      this.$route.query.copyId
    ) {
      this.viewModel.initCopyRegister(this.$route.query.copyId);
    } else {
      const id = this.$route.params.id;
      this.viewModel.init(id);
    }
  },
  computed:{
    statusSync:{
      get(){
        return this.viewModel.model.status === 'NORMAL'
      },
      set(val){
        let result = this.viewModel.model.status;
        switch(val){
          case true : { result = 'NORMAL'; break }
          case false : { result = 'HIDDEN'; break }
        }
        this.viewModel.model.status = result;
      },
    },
    isVaild(){
      return this.viewModel.registerValidatedResult()
    }
  },
  data(){
    return{
      viewModel: new NewsDetailViewModel(),
    }
  },
}
</script>
<style scoped>
.letter_preview{padding:15px;background-color:rgba(0,0,0,.8)}
.letter_preview .inner_preview{display:inline-flex;flex-direction:column;position:relative;width:100%;max-height:calc(100vh - 100px);min-height:100px;padding:40px 0 60px;border-radius:18px;background-color:#fff;white-space:normal;vertical-align:middle;box-sizing:border-box;text-align:center}

.letter_preview .layer_head{padding:0 66px 20px}
.letter_preview .layer_head .tit_layer{font-weight:700;font-size:24px;line-height:46px;color:#333}

.letter_preview .layer_body{overflow-y:auto;max-height:500px;padding:0 66px;font-weight:600;text-align:left}
</style>